<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="剧本名称" prop="scriptName">
                <a-input v-model="queryParam.scriptName" placeholder="请输入剧本名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="展会名称" prop="scriptName">
                <a-input v-model="queryParam.expoName" placeholder="请输入展会名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="标签" prop="label">
                <a-select placeholder="请选择标签" style="width: 100%" v-model="queryParam.label" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.ExpoLableEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="是否推荐" prop="isRecommend">
                <a-select placeholder="请选择是否推荐" style="width: 100%" v-model="queryParam.isRecommend" allowClear>
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="是否置顶" prop="istop">
                <a-select placeholder="请选择是否置顶" style="width: 100%" v-model="queryParam.istop" allowClear>
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="!advanced && 8 || 24" :sm="24">-->
<!--              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">-->
<!--                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>-->
<!--                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>-->
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
<!--              </span>-->
<!--            </a-col>-->
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['expo:expo/script:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" @click="$refs.CreateFormBatch.handleBatchAdd()" v-hasPermi="['expo:expo/script:add']">
          <a-icon type="plus" />批量新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['expo:expo/script:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['expo:expo/script:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleQuery" v-hasPermi="['expo:expo/script:query']">
          <a-icon type="search" />查询
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['expo:expo/script:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 增加修改 -->
      <create-form-batch
        ref="CreateFormBatch"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['expo:expo/script:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['expo:expo/script:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['expo:expo/script:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['expo:expo/script:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
        <span slot="label" slot-scope="text, record">
           <custom-dict-tag :options="customDict.ExpoLableEnum" :value="record.label"/>
        </span>
        <span slot="isTop" slot-scope="text, record">
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isTop"/>
        </span>
        <span slot="isRecommend" slot-scope="text, record">
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isRecommend"/>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listExpoScript, delExpoScript } from '@/api/expo/expoScript'
import CreateForm from './modules/CreateForm'
import CreateFormBatch from './modules/CreateFormBatch'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'
import CustomDictTag from '@/components/DictCustomTag'
export default {
  name: 'ExpoScript',
  components: {
    CreateForm,
    CreateFormBatch,
    CustomDictTag
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        expoId: null,
        scriptId: null,
        scriptName: null,
        expoName: null,
        label: null,
        reason: null,
        isRecommend: null,
        isTop: null,
        callValue: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '展会名称',
          dataIndex: 'expoName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本名称',
          dataIndex: 'scriptName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标签',
          dataIndex: 'label',
          scopedSlots: { customRender: 'label' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '期待原因',
          dataIndex: 'reason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否推荐',
          dataIndex: 'isRecommend',
          scopedSlots: { customRender: 'isRecommend' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否置顶',
          dataIndex: 'isTop',
          scopedSlots: { customRender: 'isTop' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '打call值(含虚拟)',
          dataIndex: 'callValue',
          ellipsis: true,
          align: 'center'
        },
       /* {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: { ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询展会剧本列表 */
    getList () {
      this.loading = true
      listExpoScript(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        expoId: undefined,
        scriptId: undefined,
        scriptName: undefined,
        expoName: undefined,
        label: undefined,
        reason: undefined,
        isRecommend: undefined,
        isTop: undefined,
        callValue: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delExpoScript(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('expo/expo/script/export', {
            ...that.queryParam
          }, `expo/script_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
